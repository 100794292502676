<template>
    <div>
        <section>
            <div class="container">
                <div class="has-text-centered about-us mt-5 mb-5">
                    <h1 class="title">About Us</h1>
                    <h2 class="subtitle who-we-are">Who we are</h2>
                    <div class="content pl-2 pr-2">
                        <Marked :input="getContent('about_goodroutes').content" />
                    </div>
                </div>
            </div>
        </section>
        <template v-if="getAboutBios.length">
            <section
                class="about mt-5 mb-5 pt-6 pb-6"
                :class="{
                    'about-blue' : i % 2,
                }"
                v-for="(about, i) in getAboutBios"
                :key="'about'+i"
            >
                <div class="about-person">
                    <div
                        class="columns"
                        :class="{
                            'is-flex-direction-row-reverse' : i % 2,
                        }"
                    >
                        <div class="column">
                            <figure class="image is-256x256">
                                <img :src="bioImage(about.title)">
                            </figure>
                        </div>
                        <div class="column about-bio">
                            <h1 class="title has-text-centered script-font">{{about.title}}</h1>
                            <div class="content pl-2 pr-2">
                                <Marked :input="about.content" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </template>
        <div class="plus-points-wrapper pb-6">
            <PlusPoints />
        </div>
        <div class="get-started p-4 pt-6">
            <div class="container">
                <div class="is-flex get-started-inner">
                    <div class="get-started-text">
                        <h2 class="title is-2 script-font mt-4 mb-1">
                            Find your Good Route
                        </h2>
                        <h3 class="title is-3 mb-2">
                            Dreaming of sipping martinis in Marseille some time soon?
                            Explore our database of guilt-free holiday ideas and find your
                            perfect trip
                        </h3>

                        <a
                            href="#"
                            class="button is-primary has-text-white mb-4"
                            @click="goToHomeSearch"
                        >
                            Get started
                        </a>
                    </div>
                    <div class="get-started-logo">
                        <img
                            alt="Good Routes Man with Drink"
                            src="../assets/gr_drink_man.png"
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Marked from '@/components/Marked.vue';
import PlusPoints from '@/components/PlusPoints.vue';

import {
    mapGetters,
    mapActions,
} from 'vuex';

export default {
    components: {
        Marked,
        PlusPoints,
    },
    mounted() {
        // get content
        this.fetchContent('about_goodroutes');
        this.fetchContentWildcard('about_bio_');
    },
    computed: {
        ...mapGetters('content', [
            'getContent',
            'getAboutBios',
        ]),
    },
    methods: {
        ...mapActions('content', [
            'fetchContent',
            'fetchContentWildcard',
        ]),
        goToHomeSearch() {
            this.$router.push(
                {
                    path: '/',
                },
            );
            const app = document.getElementById('app');
            this.$smoothScroll({
                scrollTo: app,
                hash: '#search',
            });
        },
        bioImage(title) {
            // get the first name from the title
            const filename = title.split(' ')[0];
            return `https://res.cloudinary.com/djc9m81hu/image/upload/ar_1.0,c_thumb,w_0.9,g_face/v1620926634/About%20us/${filename}.jpg`;
        },
    },
};
</script>

<style scoped lang="scss">
$about-width: 900px;

.about-us {
    max-width: $about-width;
    margin: 0 auto;
    text-align: center;

    .who-we-are {
        background-color: $pink;
        border-radius: 2em;
        display: inline-block;
        font-weight: $font-semi-bold;
        padding: 0.25em 0.5em;
        font-size: 23px;
        line-height: 31px;
    }
}

.about {
    clear: left;

    &.about-blue {
        background-color: $skyBlue;

        .image {
            transform: rotateZ(-5deg);
        }
    }

    .about-person {
        margin: 0 auto;
        overflow: hidden;
        max-width: $about-width;
    }

    .image {
        width: 75%;
        margin: 2em auto;
        border: 1em solid $pink;
        transform: rotateZ(5deg);
    }

    .about-bio {
        h1 {
            font-size: 62px;
        }
        p {
            font-size: 20px;
        }
    }

}

.plus-points-wrapper {
    max-width: $about-width;
    margin: 0 auto;
}

.get-started {
    @include clip-down-top-left;
    background-color: $pink;
    text-align: center;
    .title.is-2 {
        font-size: 55px;
        line-height: 44px;
    }
    .title.is-3 {
        font-size: 20px;
        line-height: 28px;
    }
    .get-started-inner {
        flex-direction: column;
    }
    .get-started-logo {
        padding: 0 2em;
        img {
            max-width: 200px;
        }
    }
}

@media screen and (min-width: $desktop) {
    .about {
        &.about-blue {
            @include clip-down-left;
        }
    }
    .get-started {
        text-align: left;
        display: flex;
        justify-content: center;
        .get-started-inner {
            flex-direction: row;
        }
    }
}
</style>
